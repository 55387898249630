// @flow strict
import { Link } from "gatsby";
import React from "react";
import type { Edges } from "../../types";
import {
  feed,
  feed__item,
  feed__item_description,
  feed__item_meta,
  feed__item_meta_category,
  feed__item_meta_category_link,
  feed__item_meta_divider,
  feed__item_meta_time,
  feed__item_title,
  feed__item_title_link,
} from "./Feed.module.scss";

type Props = {
  edges: Edges,
};

const Feed = ({ edges }: Props) => (
  <div className={feed}>
    {edges.map((edge) => (
      <div className={feed__item} key={edge.node.fields.slug}>
        <div className={feed__item_meta}>
          <time
            className={feed__item_meta_time}
            dateTime={new Date(edge.node.frontmatter.date).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" }
            )}
          >
            {new Date(edge.node.frontmatter.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
          </time>
          <span className={feed__item_meta_divider} />
          <span className={feed__item_meta_category}>
            <Link
              to={edge.node.fields.categorySlug}
              className={feed__item_meta_category_link}
            >
              {edge.node.frontmatter.category}
            </Link>
          </span>
        </div>
        <h2 className={feed__item_title}>
          <Link className={feed__item_title_link} to={edge.node.fields.slug}>
            {edge.node.frontmatter.title}
          </Link>
        </h2>
        <p className={feed__item_description}>
          {edge.node.frontmatter.description}
        </p>
      </div>
    ))}
  </div>
);

export default Feed;
